<p-tabView *ngIf="mail_id && !resendMail">
  <p-tabPanel header="{{'general.details' | translate}}">
    <div class="">
        <div class="col-lg-12 animated fadeInRight">
          <div class="mail-box-header">
            <div class="mail-tools tooltip-demo m-t-md">
              <h3>
                <span class="title">{{'component.mailing.detail.subject'|translate}}: </span>{{email.subject}}
              </h3>
              <span class="title">{{'component.mailing.detail.from'|translate}}: </span>{{email.from}}
              <div style="float: right; font-size: 13px;">{{ email.date | date: 'dd/MM/yyyy hh:mm'}}</div>

            </div>
          </div>
          <div class="mail-box">
            <div class="mail-body">
              <iframe id="mail-preview-iframe" src="about:blank"></iframe>
              <!--<p [innerHTML]="email.body"></p>-->
              <!--<p [innerHTML]="sanitizer.bypassSecurityTrustHtml(email.body)"></p>-->
            </div>
            <div class="mail-attachment">
              <p>
                <span>
                  <i class="fa fa-paperclip"></i> {{email.files.length}} {{'attachment.entity.name_plural'|translate}}
                </span>
              </p>
              <div class="attachments">
                <div class="file-box" *ngFor="let file of email.files; let i = index">
                  <div class='file'>
                    <a href='{{getUrl(file.guid)}}' target="_blank">
                      <span class="corner"></span>
                      <div class='icon'>
                        <img alt='image' class='img-fluid' src='{{getUrlIcon(file.mimetype)}}' width='40' />
                      </div>
                      <div class='file-name text-align-center'>
                        <div class='titleEllipsis' style='font-size: 12px; '>
                          <strong>{{file.filename}}</strong>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
    </div>
  </p-tabPanel>

  <p-tabPanel *ngIf="mail_id && !resendMail && email.direction != 'in'">
    <ng-template pTemplate="header">
      <span class="p-tabview-title ng-star-inserted">
        {{'component.mailing.detail.recipients'|translate}}
        <div *ngIf="email.direction!='in'"
          class="p-ml-2 badge badge-block {{(countSentsMessages == total_tos ? 'badge-success' : 'badge-danger')}}">
          {{countSentsMessages}}/{{total_tos}}</div>
      </span>
    </ng-template>

    <div class="p-grid">
      <div class="p-col-6 p-field">
        <label for="status">{{'notificationTo.entity_fields.state'|translate}}</label>
        <p-dropdown name="state" [options]="statusOptions" [(ngModel)]="notificationsToFilters.state"
          (onChange)="onStateChange($event)" optionLabel="state" optionValue="value" [showClear]="true"
          placeholder="{{'general.select' | translate}}">
        </p-dropdown>
      </div>
      <div class="p-col-6 p-field">
        <label for="mails">{{'notificationTo.entity_fields.email'|translate}}</label>
        <input name="mails" type="text" pInputText [(ngModel)]="notificationsToFilters.email" (keyup)="search()" />
      </div>
    </div>

    <p-table #grid [value]="notificationsTo">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="state" width="140">{{'notificationsTo.entity_fields.state'|translate}}</th>
          <th pSortableColumn="mails">{{'notificationsTo.entity_fields.email'|translate}}</th>
          <th pSortableColumn="times_open">{{'notificationsTo.entity_fields.times_open'|translate}}</th>
          <th pSortableColumn="last_time_opened">{{'notificationsTo.entity_fields.last_time_opened'|translate}}</th>
          <th width="70"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-notificationTo>
        <tr (dblclick)="viewInfo(notificationTo)">
          <td [innerHTML]="getState(notificationTo.state)" width="140"></td>
          <td>{{notificationTo.recipient_type == 'to'?'Para':notificationTo?.recipient_type?.toUpperCase()}}:
            {{notificationTo.mails}}</td>
          <td>{{notificationTo.times_open?notificationTo.times_open:"0"}}</td>
          <td>
            <div *ngIf="notificationTo.last_time_opened != null">
              {{notificationTo.last_time_opened | date: 'dd/MM/yyyy HH:mm:ss'}}
            </div>
            <div *ngIf="notificationTo.last_time_opened == null">
              {{'component.mailing.detail.not_opened'|translate}}
            </div>
          </td>
          <td class="grid-actions">
            <!--a (click)="resendMail(notificationsTo)">
              <fa-icon [icon]="['fas', 'share']"></fa-icon>
            </a-->
            <a (click)="viewInfo(notificationTo)">
              <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
            </a>
            <!--<a (click)="delete(configurationType)" class="color-danger"><fa-icon [icon]="['far', 'trash-alt']"></fa-icon></a>-->
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="5">
            {{'general.not_data' | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>

  </p-tabPanel>

  <p-tabPanel header="{{'component.mailing.detail.assignment'|translate}}"
    *ngIf="mail_id && !resendMail && email.direction == 'in' && email.status != 'received'">

    <p-table #grid [value]="notificationsTo">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="mails">{{'notificationTo.entity_fields.mails'|translate}}</th>
          <th pSortableColumn="organization">{{'notificationTo.entity_fields.organization_id'|translate}}</th>
          <th pSortableColumn="contact">{{'notificationTo.entity_fields.contact_id'|translate}}</th>
          <!--<th pSortableColumn="state">Estado</th>-->
          <th width="150"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-notificationTo>
        <tr (dblclick)="viewInfo(notificationTo)" *ngIf="notificationTo.state != 'discarded'">
          <td>
            {{notificationTo.mails}}
          </td>

          <td class="p-field">
            <p-dropdown name="organization_id" [(ngModel)]="notificationTo.organization_id" [filter]="true"
              filterBy="name" [options]="organizations" placeholder="{{'general.select'|translate}}" optionLabel="name"
              optionValue="id" [showClear]="true" appendTo="body" (onChange)="onChangeOrganization(notificationTo)"
              (onFilter)="onOrganizationFilter($event)"></p-dropdown>
          </td>
          <td class="p-field">
            <p-dropdown name="contact_id" [(ngModel)]="notificationTo.contact_id" [filter]="true" filterBy="fullname"
              [options]="contacts[notificationTo.id]" optionLabel="fullname"
              placeholder="{{'general.select'|translate}}" optionValue="id" [showClear]="true" appendTo="body"
              [disabled]="!notificationTo.organization_id" (onChange)="onChangeContact(notificationTo)">
              <ng-template let-item pTemplate="item">
                {{item.fullname}}<br />
                <small>{{item.email}}</small>
              </ng-template>
            </p-dropdown>
          </td>

          <!--<td>
          <div class='badge badge-block badge-success' *ngIf="notificationTo.state == 'assigned'">Asignado</div>
          <div class='badge badge-block badge-warning' *ngIf="notificationTo.state == 'pending_assign'">
            Pendiente de asignar</div>
          <div class='badge badge-block badge-danger' *ngIf="notificationTo.state == 'discarded'">Descartado
          </div>
        </td>-->

          <td class="grid-actions">
            <p-button (click)="discardNotificationTo(notificationTo)" styleClass="p-button-danger">
              <fa-icon
                [icon]="['fas', 'times']"></fa-icon>&nbsp;{{'component.mailing.detail.delete_multiple'|translate}}
            </p-button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="2">
            {{'general.not_data' | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-mt-4 p-d-flex p-jc-end">
      <button pButton (click)="assign()" icon="pi pi-check"
        label="{{'component.mailing.detail.edit_multiple'|translate}}" [disabled]="!allNotificationsAssigned"></button>
    </div>
  </p-tabPanel>

  <p-tabPanel header="{{'campaign.entity.name_plural' | translate}}" *ngIf="campaignPermission">
    <button class="p-ml-2 p-d-block" icon="pi pi-save" pButton type="button"
      label="{{'component.integrations.add'|translate}}" (click)="addCampaigns()"></button>

    <p-table [value]="campaigns" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>Nombre</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-campaign>
        <tr>
          <td>{{ campaign.name }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-tabPanel>
</p-tabView>

<div *ngIf="!mail_id || resendMail">
  <div class="p-col-12" id="mailDetail">
    <div class="p-card">
      <div class="p-grid p-col-12">
        <div class="p-col-12 p-field" *ngIf="!mail_id && templates.length > 0">
          <label for="template">{{'template.entity.name'|translate}}</label>
          <p-dropdown name="template" [(ngModel)]="email.template_id" [options]="templates" optionLabel="name"
            (onChange)="loadTemplateValue($event)" optionValue="id" [showClear]="true" appendTo="body"
            [disabled]="disableFields" [filter]="true" filterBy="name" (onFilter)="customFilterFunction($event)">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-field">
          <label for="from">
            {{'component.mailing.detail.from'|translate}}
            <a (click)="openEditUser()"
              style="float: right">{{'component.mailing.detail.configure_mail_accounts'|translate}}</a>
          </label>
          <p-dropdown name="from" [(ngModel)]="email.mail_account_id" [options]="emails" optionValue="mailAccount.id"
            [filter]="true" filterBy="mailAccount.name" required="true" optionLabel="mailAccount.name"
            placeholder="{{'general.select' | translate}}" [disabled]="disableFields"></p-dropdown>
        </div>
        <div class="p-col-12 p-field p-fluid">
          <label for="to">
            {{'component.mailing.detail.to'|translate}}

            <a (click)="changeCCCblock()" *ngIf="!showCCCblock"
              style="float: right">{{'general.show_ccc'|translate}}</a>
            <a (click)="changeCCCblock()" *ngIf="showCCCblock" style="float: right">{{'general.hide_ccc'|translate}}</a>
            <a (click)="openUsersSearcher('to')"
              style="float: right; margin-right: 10px;">{{'general.action.search'|translate}}</a>
          </label>
          <p-autoComplete [(ngModel)]="email.to" [suggestions]="searchResults" class="topAutoComplete"
            (completeMethod)="filterSearch($event)" field="email" [multiple]="true"
            [disabled]="disableFields"></p-autoComplete>
        </div>
        <div class="p-col-12 p-field p-fluid" *ngIf="showCCCblock">
          <label for="ccc">{{'general.ccc'|translate}}
            <a (click)="openUsersSearcher('ccc')"
              style="float: right; margin-right: 10px;">{{'general.action.search'|translate}}</a>
          </label>
          <p-autoComplete [(ngModel)]="email.ccc" [suggestions]="searchResults" class="topAutoComplete"
            (completeMethod)="filterSearch($event)" field="email" [multiple]="true"
            [disabled]="disableFields"></p-autoComplete>
        </div>
        <div class="p-col-12 p-field p-fluid" *ngIf="showCCCblock">
          <label for="bcc">{{'general.bcc'|translate}}
            <a (click)="openUsersSearcher('bcc')"
              style="float: right; margin-right: 10px;">{{'general.action.search'|translate}}</a>
          </label>
          <p-autoComplete [(ngModel)]="email.bcc" [suggestions]="searchResults" class="topAutoComplete"
            (completeMethod)="filterSearch($event)" field="email" [multiple]="true" [disabled]="disableFields">
          </p-autoComplete>
        </div>
        <div class="p-col-12 p-field">
          <label for="subject">{{'notificationTo.entity_fields.subject'|translate}}</label>
          <input name="subject" pInputText type="text" [(ngModel)]="email.subject" [disabled]="disableFields">
        </div>
        <div class="p-col-12 p-field">
          <label for="message">{{'notificationTo.entity_fields.message'|translate}}</label>
          <div *ngIf="showEditor">
            <editor #editor name="message" [(ngModel)]="email.body" cloudChannel="5" [init]="tinyMceConfig" [disabled]="disableFields" plugins="link media image fullpage fullscreen code table quickbars"></editor>
          </div>
        </div>
        <div class="p-col-12 p-field" style="position:relative">
          <label for="myfile[]">{{'attachment.entity.name_plural'|translate}}</label>
          <!--<p-fileUpload name="myfile[]" url="/api/attachments/upload" (onError)="onErrorUpload($event)"
              (onUpload)="onBasicUpload($event)" multiple="multiple" [showUploadButton]="false" [auto]="true" chooseLabel="Subir adjuntos mail">
          </p-fileUpload>-->
          <p-fileUpload name="myfile[]" [showUploadButton]="false" url="/api/attachments/upload"
            (onError)="onErrorUpload($event)" (onUpload)="onBasicUpload($event)" multiple="multiple"
            [showUploadButton]="false" [auto]="true" chooseLabel="Subir adjuntos" [disabled]="disableFields">
            <ng-template pTemplate="content">
              <!--<ul *ngIf="email.files.length">
                <li *ngFor="let file of email.files; let i = index">
                  <a (click)="deleteAttachment(file.guid, i)">
                    <i class="pi pi-times-circle "
                       style="font-size: 12px;margin-right: 10px;"
                       *ngIf="!disableFields"></i>
                  </a>
                  {{file.description ? file.description:file.filename}} - {{file.size}} bytes
                </li>
              </ul>-->
              <div class="p-grid" *ngIf="email.files.length">
                <div class="p-col-3 p-p-4" *ngFor="let file of email.files; let i = index">

                  <p-card>
                    <a (click)="deleteAttachment(file.guid, i)">
                      <i class="pi pi-times-circle "
                        style="font-size: 12px;margin-right: 10px;position: absolute; right: 0px; top: 5px;"
                        *ngIf="!disableFields"></i>
                    </a>
                    <div style="display: flex; align-items: center; padding-top: 15px;">
                      <img alt="Card" src="/assets/img/mimes/{{getImage(file.mimetype)}}" width="40"
                        style="width: 40px; margin-right: 10px">
                      <div style="font-size: 12px">
                        <span>{{file.filename}} - {{file.size}} bytes</span><br />
                        <span>{{'notificationTo.entity_fields.times_open'|translate}}:
                          {{file.times_open?file.times_open:'0'}}</span>
                      </div>
                    </div>

                  </p-card>
                  <!--
  <p-card subheader="{{file.description ? file.description:file.filename}}"
          [style]="{width: '100%'}" styleClass="p-card-shadow">
    <ng-template pTemplate="header">
      <div style="text-align: center;">
        <img alt="Card" src="/assets/img/mimes/{{getImage(file.mimetype)}}" width="40" style="width: 40px;" >
      </div>
    </ng-template>
    <p>{{file.filename}} - {{file.size}} bytes</p>
    <p>Nº veces descargado: {{file.times_open?file.times_open:'0'}}</p>
  </p-card>-->
                </div>
              </div>
            </ng-template>
          </p-fileUpload>
          <p-button label="{{'component.mailing.detail.select_from_axial'|translate}}" icon="pi pi-plus"
            (click)="uploadAxial()" class="buttonSelectFromAxial" [disabled]="disableFields"></p-button>
        </div>
        <div class="p-col-12 p-field" style="position:relative" *ngIf="errorText != null && errorText.length > 0">
          <label for="errorText">{{'general.error'|translate}}</label>
          <textarea pInputTextarea name="errorText" id="errorText" autoResize="autoResize"
            disabled>{{errorText}}</textarea>
        </div>
        <!--div class="p-col-12 p-field">
            <p-fileUpload name="myfile[]" url="./api/attachments/upload.php" (onError)="onErrorUpload($event)"
                multiple="multiple" [showUploadButton]="false">
                <ng-template pTemplate="toolbar">
                    <div>Upload 3 Files</div>
                </ng-template>
                <ng-template let-file pTemplate="file">
                    <div>Nombre: {{file.name}}</div>
                </ng-template>
                <ng-template pTemplate="content" let-files>
                    <div>Additional content.</div>
                </ng-template>
            </p-fileUpload>
        </div>-->
      </div>
    </div>
  </div>
  <div class="p-col-12">
    <button pButton style="float: right;" (click)="sendEmail()"
      [disabled]="disableFields && !resendMail">{{'general.send'|translate}}</button>
  </div>
</div>
