import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { MailAccountsService } from 'src/app/configuration/mail-accounts/mail-accounts.service';
import { TemplatesService } from 'src/app/configuration/templates/templates.service';
import { SearchService } from 'src/app/core/search.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { ContactsService } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/contacts.service';
import { ReportsService } from 'src/app/reports/reports.service';
import { UserMailAccountsService } from 'src/app/users/users-mail-accounts-detail/user-mail-accounts.service';
import { UsersService } from 'src/app/users/users.service';
import { TemplatesTypesService } from '../../configuration/templates/templates-types.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { UsersDetailComponent } from '../../users/users-detail/users-detail.component';
import { MailingAttachmentsComponent } from '../mailing-attachments/mailing-attachments.component';
import { MailingToDetailComponent } from '../mailing-to-detail/mailing-to-detail.component';
import { UsersSearcherComponent } from '../users-searcher/users-searcher.component';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { UtilsService } from '../../core/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { DropdownFilterOptions } from 'primeng/dropdown';
import { LoginService } from 'src/app/login/login.service';
import { CampaignsService } from 'src/app/campaigns/campaigns.service';
import { AddCampaignsToEntityComponent } from 'src/app/campaigns/add-campaigns-to-entity/add-campaigns-to-entity.component';
import tinymce, { Editor } from 'tinymce';

@Component({
  selector: 'app-mailing-detail',
  templateUrl: './mailing-detail.component.html',
  styleUrls: ['./mailing-detail.component.scss']
})
export class MailingDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  @ViewChild("editor") public editor: Editor;
  public filterValue: string = '';
  public searchResults: any[] = [];
  public searchText: any;
  public searchResultsBCC: any[] = [];
  public searchTextBCC: any;
  public attachment: any = {};
  public meeting_users;
  public entities_ids: any[] = [];
  public total_tos: number = 0;
  public firstLoad: boolean = true;
  public allNotificationsAssigned: boolean = false;
  public notificationsToManaged: number = 0;
  public searchTextOrg: string = "";
  public filtersTemplates: any = {
    name: ""
  };
  public campaignPermission: boolean = false;
  public campaigns: any[] = [];

  public emails: any = [];
  public showCCCblock: boolean = false;
  public email = {
    //from: null,
    mail_account_id: null,
    date: null,
    to: [],
    ccc: [],
    bcc: [],
    tos: "",
    cccs: "",
    bccs: "",
    subject: "",
    template_id: null,
    body: "",
    files: [],
    entity_id: null,
    entity_pk_id: null,
    organization_id: null,
    contact_id: null,
    toInfo: [],
    status: "",
    type: "",
    direction: "out"
  }
  public mail_id;
  //public mail_account_id;
  public template_type_id;
  public notification_to_id;
  public templates: any[] = [];
  public filteredTemplates: any[] = [];
  public disableFields: boolean = false;
  public errorText: string = null;
  public notificationsTo: any[] = [];
  public statusOptions = [
    //{"state":"Todos", "value":null},
    { "state": this.translateService.instant('component.mailMarketing.sent_status'), "value": "sent" },
    { "state": this.translateService.instant('component.mailMarketing.default_status'), "value": "pending" },
    { "state": this.translateService.instant('component.mailMarketing.programmed_status'), "value": "programmed" },
    { "state": this.translateService.instant('component.mailMarketing.error_status'), "value": "error" },
  ]

  public organizations: any[] = [];
  public contacts: any[] = [];
  public notificationsToFilters: any = {};
  public countSentsMessages: number;
  private is_generic: boolean = null;
  public templateTypes: any[];
  public resendMail: boolean = false;
  private emailsSignature: string = "";
  public tinyMceConfig: any;
  public showEditor: boolean = true;
  public iframeSrc: string = "";

  constructor(
    private entitiesService: EntitiesService,
    private templatesService: TemplatesService,
    private campaignsService: CampaignsService,
    private mailAccountsService: MailAccountsService,
    private searchService: SearchService,
    private loginService: LoginService,
    private userMailAccountsService: UserMailAccountsService,
    private notificationsService: NotificationsService,
    private usersService: UsersService,
    private contactsService: ContactsService,
    private organizationsService: OrganizationsService,
    private attachmentsService: AttachmentsService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private reportsService: ReportsService,
    private dialogService: DialogService,
    public sanitizer: DomSanitizer,
    public viewsService: ViewsService,
    public templateTypesService: TemplatesTypesService,
    public coreDialogService: CoreDialogService,
    private utilsService: UtilsService,
    private translateService: TranslateService
  ) {

    if (this.config.data.resendMail != undefined) this.resendMail = this.config.data.resendMail;
  }

  ngOnInit(): void {

    this.campaignPermission = this.loginService.hasPermission("CAMPAIGNS_READ");

    this.usersService.getUsersSignatureEmail(parseInt(localStorage.getItem("userId"))).subscribe(
      (userHtml: any) => {
        this.email.body = userHtml.emailsSignature;
        this.emailsSignature = userHtml.emailsSignature;
      }
    );

    //cargamos las propiedades
    //this.mail_account_id = this.config.data.mail_account_id;
    this.mail_id = this.config.data.mail_id;
    this.email.entity_id = this.config.data.entity_id
    this.email.entity_pk_id = this.config.data.entity_pk_id;
    this.meeting_users = this.config.data.meeting_users;
    this.notification_to_id = this.config.data.notification_to_id;
    this.is_generic = this.config.data.is_generic;

    //Se cargan los tipos de templates porque se manda al abrir el popup (MAIL, MEETING, MAIL_INTERNAL, etc)
    this.templateTypesService.all({}).subscribe({
      next: (data: any) => {
        this.templateTypes = data.rows;

        if (typeof (this.config.data.template_type_code) !== "undefined") {
          this.template_type_id = this.templateTypes.filter(m => m.code == this.config.data.template_type_code)[0].id;
        } else {
          this.template_type_id = this.config.data.template_type_id;
        }

        if (this.mail_id == null) {

          if (this.template_type_id == null) {
            //Si no ha llegado el tipo de mail, se pone por defecto MAIL
            this.template_type_id = 1;
          }
          if (this.config.data.to != null) {
            if (typeof this.config.data.to == "string") {
              this.email.to = [{ email: this.config.data.to }];
            } else {
              //Ha mandado un objecto
              if (this.config.data.to.email) { this.email.to = [this.config.data.to]; }
            }
            this.email.toInfo.push(this.config.data.to);
          }
          if (this.meeting_users != null && this.meeting_users.length > 0) {
            var i = 1;
            this.meeting_users.forEach(external_user => {
              this.email.to.push({ email: external_user.email, name: external_user.name });
              this.email.toInfo.push(external_user);
            });
          }
          this.email.tos = String(this.email.to.map(function (item) {
            return item['email'];
          }));

          if (this.config.data.organization_id != null) this.email.organization_id = this.config.data.organization_id;
          if (this.config.data.contact_id != null) this.email.contact_id = this.config.data.contact_id;

        } else {

          this.loadToInformation();
          //this.disableFields = true;
        }

        this.loadEmails(() => {
          if (this.config.data.mail_id > 0) {
            this.loadEmail(() => {
              this.loadNotificationsTo();
              this.loadMailingAttachments();
            });
          }
        });

        //Se buscan las entidades relacionadas siempre salvo cuando sean internas. Por eso se pasa el searchRelated.
        if (typeof (this.config.data.searchRelated) === "undefined") this.config.data.searchRelated = true;
        if (this.email.entity_id != null && this.config.data.searchRelated) this.loadRelatedEntities();
        if (!this.config.data.searchRelated) this.loadTemplates();
        if (this.campaignPermission && this.mail_id != null) this.loadCampaigns();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
    this.configureTinyMce();

  }

  configureTinyMce() {
    const that = this;
    this.tinyMceConfig =
    {
      selector: 'textarea#tiny',
      promotion: false,
      fullPage: true,
      height: 500,
      menubar: true,
      /*valid_elements: '*[*]',
      extended_valid_elements: 'style',
      valid_children: '+body[style]',
      custom_elements: 'style',*/
      //content_style: this.template_content_style,
      external_plugins: {
        fullpage: '/assets/tinymce/fullpage.plugin.js'
      },
      toolbar: [
        'undo redo | bold italic underline | fontselect fontsizeselect fontsize fontfamily  formatselect |' +
        'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist |' +
        'forecolor casechange permanentpen formatpainter removeformat | fullscreen | insertfile image media pageembed link | table quickbars | variablesButton  variablesLinesButton | previewButton'
      ],
      plugins: 'quickbars',
      quickbars_insert_toolbar: "quicktable",
      toolbar_mode: 'wrap',
      language: 'es',
      language_url: '/assets/tinymce/langs/es.js',
      relative_urls: false,
      remove_script_host: false,
      document_base_url: 'https://app.axialcrm.com/',
      file_picker_types: 'image',
      paste_data_images: false,
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function (e) {
          var file = e.target["files"][0];
          var reader = new FileReader();
          reader.onload = (event: any) => {
            var datafile = {
              FileName: file.name,
              ContentType: file.type,
              Length: file.size,
              b64: event.target.result
            };
            that.templatesService.UploadImage(datafile).subscribe({
              next: (data: any) => {
                cb(data.location);
              },
              error: (error: any) => {
                that.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    };
  }

  loadEmail(cb: Function) {
    this.notificationsService.get(this.mail_id).subscribe({
      next: (data: any) => {
        this.email = data;
        this.email.files = [];
        this.email.body = data.content;

        //esablecemos html al iframe
        setTimeout(() => {
          var iframe: any = document.getElementById("mail-preview-iframe");
          var contentDocument: any = iframe?.contentDocument;
          if (contentDocument != null) {
            contentDocument.open();
            contentDocument.write(this.email.body);
            contentDocument.close();
          }
        });
        let mailAccount = this.emails.find(m => m.mailAccount.id == this.email.mail_account_id);
        if (mailAccount == null) this.email.mail_account_id = null;

        //si reenviamos, añadimos texto de reenvio
        if (this.resendMail) {
          const regex = new RegExp(
            "<body(.*?)>"
          );
          let match = regex.test(this.email.body);

          var date = new Date(data["date"]).toLocaleString();
          var from = data["from"];
          var text = this.translateService.instant('component.mailing.detail.onDate_user_wrote', { date: date, user: from });
          if (match) {
            this.email.body = this.email.body.replace(regex, (match: string) => {
              return match + "<br><br><br>" + this.emailsSignature + "<br><br>----------------------------------------------------------<br>" + text + ":<br><br>";
            });
          } else {
            this.email.body = "<br><br><br>" + this.emailsSignature + "<br><br>----------------------------------------------------------<br>" + text + ":<br><br>" + data["content"];
          }

          this.email.subject = "RE: " + data.subject;
        }

        this.loadMailTos(data["id"]);

        if (cb != null) cb();
      },
      error: (error: any) => {

      }
    });
  }

  getUrl(guid) {
    let notificationTo = this.notification_to_id;
    if (typeof (notificationTo) == "undefined") notificationTo = "";
    var url = "https://app.axialcrm.com/api/PublicAttachments/" + localStorage.getItem("companyId") + "/" + guid + "/" + notificationTo + "/download";
    return url
  }

  getUrlIcon(mimetype) {
    var srcImage = "https://app.axialcrm.com/assets/img/mimes/";
    var ret = "other.png";
    if (mimetype == "application/pdf") {
      ret = "pdf.png";
    } else if (mimetype == "image/png" || mimetype == "image/jpeg" || mimetype == "image/jpg" || mimetype == "image/gif" || mimetype == "image/bmp") {
      ret = "img.png";
    } else if (mimetype == "application/msword" || mimetype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      ret = "word.png";
    } else if (mimetype == "application/vnd.ms-excel" || mimetype == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      ret = "xls.png";
    } else if (mimetype == "application/vnd.ms-powerpoint" || mimetype == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      ret = "pwt.png";
    }
    return srcImage + ret;
  }

  loadNotificationsTo() {
    var params = { notification_email_id: this.mail_id }
    params = Object.assign(params, this.notificationsToFilters);

    this.notificationsService.allNotificationsTo(params).subscribe({
      next: (data: any) => {
        this.notificationsTo = data.rows;
        if (this.email.direction == "in") this.searchOrganizations();

        if (data.totalRows > 0) {
          var aux = this.notificationsTo.filter(m => m.recipient_type == "to");
          this.email.entity_pk_id = aux[0]["entity_pk_id"];
          this.email.contact_id = aux[0]["contact_id"];
          this.email.organization_id = aux[0]["organization_id"];
        }
        if (this.firstLoad) {
          this.countSentsMessages = this.notificationsTo.filter(nt => (nt.state == "sent" || nt.state == "discarded")).length;
          this.total_tos = this.notificationsTo.length;
        }
        this.firstLoad = false;
        this.countNotificationsToManaged();
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  loadToInformation() {
    if (this.notification_to_id) {
      this.notificationsService.getNotificationTo(this.notification_to_id).subscribe({
        next: (data: any) => {
          this.errorText = data.text;
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }
  }

  loadMailingAttachments() {
    this.attachmentsService.all({ mail_id: this.mail_id }).subscribe({
      next: (data: any) => {
        this.email.files = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  loadMailTos(notification_email_id) {
    this.notificationsService.allNotificationsTo({ notification_email_id: notification_email_id }).subscribe(
      data => {
        this.email.to = data.rows.filter(m => m.recipient_type == "to").map(m => ({ "email": m.mails }));
        this.email.bcc = data.rows.filter(m => m.recipient_type == "bcc").map(m => ({ "email": m.mails }));
        this.email.ccc = data.rows.filter(m => m.recipient_type == "cc").map(m => ({ "email": m.mails }));
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }

  loadRelatedEntities() {
    this.entitiesService.getRelatedEntities(this.config.data.entity_id).subscribe({
      next: (data: any) => {
        this.entities_ids = data;
        this.loadTemplates();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadTemplates() {
    if (this.email.entity_id && this.template_type_id) {
      this.templatesService.all({ template_type_id: this.template_type_id, entity_id: this.email.entity_id, related_entities_ids: this.entities_ids, name: this.filtersTemplates.name }).subscribe({
        next: (data: any) => {
          this.templates = data.rows;
          this.filteredTemplates = data.rows;
          //Si es un mail interno, se carga la primera plantilla por defecto y se añade la opción 'ninguna' al final.
          if (this.config.data.template_type_code == "MAIL_INTERNAL") {
            if (this.templates.length > 0) {
              this.email.template_id = this.templates[0].id
              this.loadTemplateValue({ value: this.email.template_id });
            }
          }
          this.templates.unshift({ name: this.translateService.instant('component.mailing.detail.none') });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  loadContacts(notificationTo) {
    this.contactsService.all({ _sortBy: "fullname", _sortDirection: "asc", organization_id: notificationTo.organization_id }).subscribe({
      next: (data: any) => {
        this.contacts[notificationTo.id] = data.rows;
      }, error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadOrganizations() {
    this.organizationsService.all({ _sortBy: "name", _sortDirection: "asc" }).subscribe({
      next: (data: any) => {
        this.organizations = data.rows;
      }, error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });

  }

  searchOrganizations() {
    let params: any = {
      text: this.searchTextOrg
    };
    if (this.notificationsTo != null) params.include_organization_id = this.notificationsTo.filter(m => m.organization_id != null).map(m => m.organization_id);
    this.organizationsService.getCombo(params).subscribe({
      next: (data: any) => {
        this.organizations = data.rows;

        if (this.email.direction == 'in') {
          this.notificationsTo.forEach(notificationTo => {
            if (notificationTo.organization_id != null) this.loadContacts(notificationTo);
          });
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadTemplateValue(event) {
    //Esto carga el HTML de la plantilla en el editor:
    if (event.value) {
      this.templatesService.get(event.value).subscribe(
        data => {
          this.showEditor = false;
          this.email.body = data.html;
          var template = {
            name: "blank",
            html: "blank",
            subject: data.subject
          }
          /* TODO: Traer en el this.config el entity_id y el entity_id_pk y pasárselos a la siguiente función.*/
          this.templatesService.toHTML(event.value, this.email.entity_id, this.email.entity_pk_id, template).subscribe({
            next: (data: any) => {
              //var html = this.preserveTextFormat(data.html);
              this.email.body = data.html;
              //Si el asunto está vacío, cambia su valor por el título de la plantilla:
              if (this.email.subject == null || this.email.subject == "" || this.email.subject.length == 0) {
                this.email.subject = data.subject;
              }
              //this.res = this.sanitizer.sanitize(SecurityContext.HTML,data.html);

              //estraemos style del html y lo cargamos en el editor
              var style = "";
              if (this.email.body.indexOf("<style>") > 0) {
                var styleStartIdx = this.email.body.indexOf("<style>");
                var styleEndIdx = this.email.body.indexOf("</style>");
                style = this.email.body.substring(styleStartIdx, styleEndIdx);
              }
              this.tinyMceConfig.content_style = style;
              //this.editor.iframeElement.contentDocument.getElementsByTagName('style')[1].innerHTML = style;
              //this.editor.remove();
              setTimeout(() => { this.showEditor = true; }, 0);
              //tinymce.init(this.tinyMceConfig);

            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        }
      );
    } else {
      this.email.body = "";
    }
  }

  preserveTextFormat(text) {
    text = text.replace('<span >', '<span>');
    // Reemplazar las tabulaciones con la entidad &emsp;
    text = text.replace(/\t/g, '&emsp;');
    // Reemplazar los saltos de línea con la etiqueta <br>
    text = text.replace(/\n/g, '<br>');
    // Reemplazar los espacios en blanco con la entidad &nbsp;
    text = text.replace(/\s/g, '&nbsp;');
    return text;
  }

  loadEmails(cb: Function) {
    this.userMailAccountsService.all({ user_id: localStorage.getItem("userId") }).subscribe({
      next: (data: any) => {
        this.emails = data.rows;
        //Eliminado por defecto mostrar el email del usuario logueado... lo ha pedido el 03/11/2022
        //this.emails.unshift({ mailAccount: { name: localStorage.getItem("email") } });
        if (cb != null) cb();

      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  sendEmail() {
    /*
       if (this.email.tos != null && this.email.tos.length != 0) {
         this.email.tos = String(this.email.to?.map(function (item) {
           return item['email'];
         }));
       } else {
         this.email.tos = "";
       }*/

    if (this.email.bcc != null && this.email.bcc.length != 0) {
      this.email.bccs = String(this.email.bcc?.map(function (item) {
        return item['email'];
      }));
    } else {
      this.email.bccs = "";
    }

    if (this.email.ccc != null && this.email.ccc.length != 0) {
      this.email.cccs = String(this.email.ccc?.map(function (item) {
        return item['email'];
      }));
    } else {
      this.email.cccs = "";
    }

    if (this.email.mail_account_id == null ||
      (this.email.to.length == 0 && this.email.cccs == "" && this.email.bccs == "")) {

      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: "Por favor, Designe la cuenta de correo y los destinatarios." });
      return;
    }
    this.reportsService.sendEmail(this.email).subscribe({
      next: (res: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant('component.mailing.detail.sending_email') });
        this.dynamicDialogRef.close("success");
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
    // this.mailAccountsService.send(this.email.from, this.email).subscribe(
    //   data => {
    //     this.messageService.add({ closable: false, severity: "success", detail: "Email enviado correctamente" });
    //   },
    //   error => {
    //     /*if(this.email.from)*/ this.messageService.add({ closable: false, severity: "error", detail: "No está configurada la cuenta de correo seleccionada para poder mandar emails desde la herramienta." });
    //     //if(!this.email.from) this.messageService.add({ closable: false, severity: "error", detail: "No hay una cuenta de correo seleccionada."});
    //   }
    // )
  }

  filterSearch(event) {
    let filtered: any[] = [];
    let query = event.query;
    if (query.length >= 4) {
      filtered.push(
        {
          email: query
        }
      );
      this.searchService.onlyPeople({ email: query }).subscribe({
        next: (data: any) => {
          data.forEach(element => {
            filtered.push(element);
          });
          this.searchResults = filtered;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
  onErrorUpload(event) {
    this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant('component.mailing.detail.error_upload') });
  }
  onBasicUpload(event) {
    let i = 0;
    let guids = [];
    if (event.files.length > 1) guids = event.originalEvent.body.guids;
    else if (event.files.length == 1) guids = [event.originalEvent.body.guid];
    for (let f of event.files) {
      var file = { filename: "", mimetype: "", guid: "", size: "" };
      file.filename = f.name;
      file.mimetype = f.type;
      file.guid = guids[i];
      file.size = f.size;
      this.email.files.push(file);
      i++;
    }
    /* if (event.originalEvent.body.off) {
       var guidNum = 0;
       event.originalEvent.body.files.forEach(file => {
         var datafile = {
           description: file.fileName,
           filename: file.fileName,
           mimetype: file.contentType,
           size: file.length,
           guid: event.originalEvent.body.guids[guidNum],
           entity_id: this.config.data.entity_id,
           entity_pk_id: this.config.data.entity_pk_id,
           organization_id: this.config.data.organization_id,
           //TODO: ¿Darle un valor a attachment_type_id?:
           //attachment_type_id: this.attachment.attachment_type_id
         };
         this.attachmentsService.add(datafile).subscribe(
           data => {
           },
           error => {
             this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
           }
         );
         guidNum = guidNum + 1;
       });
     } else {
       this.attachment.description = event.originalEvent.body.filename;
       this.attachment.filename = event.originalEvent.body.filename;
       this.attachment.mimetype = event.originalEvent.body.mimetype;
       this.attachment.size = event.originalEvent.body.size;
       this.attachment.guid = event.originalEvent.body.guid;
       this.attachment.entity_id = this.config.data.entity_id;
       this.attachment.entity_pk_id = this.config.data.entity_id_pk;
       this.attachment.organization_id = this.config.data.organization_id;
       this.attachmentsService.add(this.attachment).subscribe(
         data => {
         },
         error => {
           this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
         }
       );
     }*/
  }

  deleteAttachment(guid, index) {
    this.email.files.splice(index, 1);
  }

  changeCCCblock() {
    this.showCCCblock = !this.showCCCblock;
  }

  uploadAxial() {
    const ref = this.dialogService.open(MailingAttachmentsComponent, {
      data: {
        entity_id: this.email.entity_id,
        entity_pk_id: this.email.entity_pk_id,
        organization_id: this.email.organization_id,
        is_generic: this.is_generic
      },
      header: this.translateService.instant('component.mailing.detail.add_document'),
      width: "50%",
      /*baseZIndex: 1000*/
    });
    ref.onClose.subscribe((data: any) => {
      //if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data != null) {
        for (let f of data) {
          this.email.files.push(f);
        }
      }
    });
  }

  viewInfo(notificationTo) {
    const ref = this.dialogService.open(MailingToDetailComponent, {
      data: {
        notification_to_id: notificationTo.id,
        //organization_id: this.organization.id,
      },
      header: this.translateService.instant('component.mailing.detail.more_info'),
      width: '900px',
    });
  }

  clearFilters() {
    this.notificationsToFilters = {};
    this.search();
  }

  search() {
    this.loadNotificationsTo();
  }

  getState(status) {

    if (status == 'sent') {
      var sent_status_string: string = this.translateService.instant("component.mailing.sent_status");
      return "<div class='badge badge-block badge-success'>" + sent_status_string + "</div>";
    } else if (status == "pending") {
      var pending_status_string: string = this.translateService.instant("component.mailing.pending_status");
      return "<div class='badge badge-block badge-warning'>" + pending_status_string + "</div>";
    } else if (status == "programmed") {
      var programmed_status_string: string = this.translateService.instant("component.mailing.programmed_status");
      return "<div class='badge badge-block badge-info'>" + programmed_status_string + "</div>";
    } else if (status == "error") {
      var error_status_string: string = this.translateService.instant("component.mailing.error_status");
      return "<div class='badge badge-block badge-danger'>" + error_status_string + "</div>";
    } else if (status == "discarded") {
      var discarded_status_string: string = this.translateService.instant("component.mailing.discarded_status");
      return "<div class='badge badge-block badge-info'>" + discarded_status_string + "</div>";
    } else {
      var default_status_string: string = this.translateService.instant("component.mailing.default_status");
      return "<div class='badge badge-block badge-secondary'>" + default_status_string + "</div>";
    }
  }

  openEditUser() {
    const ref = this.dialogService.open(UsersDetailComponent, {
      data: {
        id: parseInt(localStorage.getItem("userId"))
      },
      //header: "Detalles del usuario",
      width: '70%',
    });
    ref.onClose.subscribe((data) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.loadEmails(null);
    });
  }


  getImage(mimetype) {
    var ret = "other.png";
    if (mimetype == "application/pdf") {
      ret = "pdf.png";
    } else if (mimetype == "image/png" || mimetype == "image/jpeg" || mimetype == "image/jpg" || mimetype == "image/gif" || mimetype == "image/bmp") {
      ret = "img.png";
    } else if (mimetype == "application/msword" || mimetype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      ret = "word.png";
    } else if (mimetype == "application/vnd.ms-excel" || mimetype == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      ret = "xls.png";
    } else if (mimetype == "application/vnd.ms-powerpoint" || mimetype == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      ret = "pwt.png";
    }
    return ret;
  }

  openUsersSearcher(destiny) {
    const ref = this.dialogService.open(UsersSearcherComponent, {
      data: {
        emails: this.email.to,
        organizationId: this.email.organization_id,
        destiny: destiny,
        viewAll: true
      },
      header: "Buscar emails",
      width: '35%',
    });
    ref.onClose.subscribe((data: any) => {
      if (data != null) {
        this.usersSearcherResults(data.emails, data.destiny);
      } else {

      }
    });
  }

  usersSearcherResults(results, destiny) {
    var temp_emails = results;/*.map((email) => {
      return { name: name, email: email }
    });*/
    if (destiny == 'to') {
      var finalMails: any[] = [];
      this.email.to?.forEach(email => { finalMails.push(email); });
      temp_emails?.forEach(email => { finalMails.push(email); });
      this.email.to = finalMails;
    } else if (destiny == 'ccc') {
      var finalMails: any[] = [];
      this.email.ccc?.forEach(email => { finalMails.push(email); });
      temp_emails?.forEach(email => { finalMails.push(email); });
      this.email.ccc = finalMails;
    } else if (destiny == 'bcc') {
      var finalMails: any[] = [];
      this.email.bcc?.forEach(email => { finalMails.push(email); });
      temp_emails?.forEach(email => { finalMails.push(email); });
      this.email.bcc = finalMails;
    }

    if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    /*
    this.email.to = this.email.to.filter(this.onlyUnique);
    this.email.ccc = this.email.ccc.filter(this.onlyUnique);
    this.email.bcc = this.email.bcc.filter(this.onlyUnique);
    */
  }
  onlyUnique(value, index, self) {
    //Realiza la función de un distinct.
    return self.indexOf(value) === index;
  }
  onStateChange($event) {
    if ($event.value == null) delete this.notificationsToFilters.state;
    this.search();
  }
  discardNotificationTo(notificationTo) {
    this.coreDialogService.confirm({
      message: '¿Quieres descartar el email para "' + notificationTo.mails + '"?',
      accept: () => {
        notificationTo.state = "discarded";
        //notificationTo.deleted = true;
        //this.saveNotificationTo(notificationTo);
        this.countNotificationsToManaged();
      },
      reject: () => {

      }
    });

  }
  onChangeOrganization(notificationTo, $event) {
    delete notificationTo.contact_id;
    if (notificationTo.organization_id != null) {
      notificationTo.state = "assigned";
      this.loadContacts(notificationTo);
      //this.saveNotificationTo(notificationTo);
    } else {
      notificationTo.state = "pending_assign";
    }
    this.countNotificationsToManaged();
  }
  onChangeContact(notificationTo) {
    if (notificationTo.organization_id != null && notificationTo.contact_id != null) {
      //this.saveNotificationTo(notificationTo);
    }
  }

  onOrganizationFilter($event) {
    this.searchTextOrg = $event.filter;
    if (this.searchTextOrg.length > 1) this.searchOrganizations();
    else if (this.searchTextOrg.length == 0) {
      this.searchOrganizations();
    }
  }

  saveNotificationsTo() {
    this.notificationsTo.forEach(not => {
      not.notificationEmails = null;
      not.organization = null;
    });
    this.notificationsService.saveNotificationsTo(this.mail_id, this.notificationsTo).subscribe(
      data => {
        this.messageService.add({ severity: 'success', summary: 'Correos asignados correctamente' });
        this.dynamicDialogRef.close();
        this.utilsService.alertsChange.emit(true);
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }
  countNotificationsToManaged() {
    //Se encarga de comprobar si todos los Notifications To han sido gestionados (asignados o descartados).
    this.notificationsToManaged = this.notificationsTo.filter(m => m.state == 'assigned' || m.state == 'discarded').length;
    this.allNotificationsAssigned = (this.notificationsToManaged == this.total_tos);
  }

  assign() {
    if (!this.allNotificationsAssigned) return;
    //Para entrar en este método, todos los notifications_to deberían estar en estado 'assigned' y/o 'discarded' (gestionados) para poder guardar cambios.
    this.saveNotificationsTo();
  }
  filterTemplates($event) {
    let filtered: any[] = [];
    let query = $event.query;
    for (let i = 0; i < (this.templates as any[]).length; i++) {
      let template = (this.templates as any[])[i];
      if (template.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(template);
      }
    }
    this.filteredTemplates = filtered;
  }
  customFilterFunction(event: any) {
    if (event.filter?.length >= 3) {
      this.filtersTemplates.name = event.filter;
      this.loadTemplates();
    }
    else if (event.filter?.length == null || event.filter?.length == 0) {
      this.filtersTemplates = "";
      this.loadTemplates();
    }
  }
  resetFunction(options: DropdownFilterOptions) {
    options.reset();
    this.filterValue = '';
  }

  loadCampaigns() {
    if (this.mail_id == null) return;
    this.campaignsService.getByElementId({ notification_email_id: this.mail_id }).subscribe({
      next: (data: any) => {
        this.campaigns = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  addCampaigns() {
    const ref = this.dialogService.open(AddCampaignsToEntityComponent, {
      data: {
        id: this.mail_id,
        entity: 'mailMarketing',
        campaign_id: (this.campaigns.length > 0 ? this.campaigns[0]?.id : null)
      },
      header: this.translateService.instant("component.campaigns.addCampaigns"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadCampaigns();
    });
  };
}
