import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { LoginService } from 'src/app/login/login.service';
import { UsersService } from 'src/app/users/users.service';
import { EntitiesService } from '../../configuration/entities.service';
import { ComponentService } from './component.service';
import { ViewComponentAddComponent } from './component/add/view-component-add.component';
import { ViewComponentCardComponent } from './component/card/view-component-card.component';
import { ViewComponentCustomComponent } from './component/custom/view-component-custom.component';
import { ViewComponentFilesComponent } from './component/files/view-component-files.component';
import { ViewComponentFormComponent } from './component/form/view-component-form.component';
import { ViewComponentGalleryComponent } from './component/gallery/view-component-gallery.component';
import { NewViewComponent } from './component/new-view/new-view.component';
import { ViewComponentTableComponent } from './component/table/view-component-table.component';
import { ViewsCustomService } from './views-custom.service';
import { ViewsService } from './views.service';
import { SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { DebugService } from '../debug.service';

@Component({
  selector: 'app-view',
  templateUrl: 'view.component.html',
  styleUrls: ['view.component.scss'],
})
export class ViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("op") op: OverlayPanel;
  @ViewChildren("table") tables: Array<ViewComponentTableComponent>;
  @ViewChildren("form") forms: Array<ViewComponentFormComponent>;
  @ViewChildren("card") cards: Array<ViewComponentCardComponent>;
  @ViewChildren("gallery") galleries: Array<ViewComponentGalleryComponent>;
  @ViewChildren("file") files: Array<ViewComponentFilesComponent>;
  @ViewChildren("custom") customs: Array<ViewComponentCustomComponent>;
  @ViewChildren("filter") filters: Array<ViewComponentFilesComponent>;

  @Output() onEvent: EventEmitter<any> = new EventEmitter();

  @Input("code") public code: string;
  @Input("model") public model: any = {};
  @Input("config") public config: any = {};

  public viewOrig: any = {};
  public view: any = {};
  public selectedView: any;
  public showConfigOptions: boolean = false;
  public allowViewPersonalize: boolean = false;
  public allowedLayouts: any[] = [
    { code: "12", name: "1 " + this.translateService.instant("view.configure.column") + " (100%)" },
    { code: "6-6", name: "2 " + this.translateService.instant("view.configure.columns") + " (50%, 50%)" },
    { code: "8-4", name: "2 " + this.translateService.instant("view.configure.columns") + " (66%, 34%)" },
    { code: "9-3", name: "2 " + this.translateService.instant("view.configure.columns") + " (75%, 25%)" },
    { code: "4-8", name: "2 " + this.translateService.instant("view.configure.columns") + " (34%, 66%)" },
    { code: "3-9", name: "2 " + this.translateService.instant("view.configure.columns") + " (25%, 75%)" },
    { code: "4-4-4", name: "3 " + this.translateService.instant("view.configure.columns") + " (34%, 33%, 33%)" }
  ];
  public layout: any[] = [];

  public component: any = null;

  public visibleByOptions: any[] = [
    { code: "all", label: this.translateService.instant("view.configure.all") },
    { code: "user", label: this.translateService.instant("view.configure.user_responsible") }
  ];
  public editableByOptions: any[] = [
    { code: "all", label: this.translateService.instant("view.configure.all") },
    { code: "user", label: this.translateService.instant("view.configure.user_responsible") }
  ];
  public optionsMetadataTo = [
    { label: this.translateService.instant("view.configure.for_all"), value: "all" },
    { label: this.translateService.instant("view.configure.onlyme"), value: "onlyme" },
    { label: this.translateService.instant("view.configure.subordinate"), value: "subordinate" }
  ];

  public entity: any = null;
  public dropdownViews: any[] = [];

  //Permisos
  public personalize_interface: boolean;
  public views_superadmin: boolean;
  public view_organizations: boolean;
  public view_meetings: boolean;
  public create_reports: boolean;
  public roles_configuration: boolean;
  public zonesPermission: boolean;
  public personalized_views_read: boolean;
  public personalized_views_write: boolean;
  public created_by: any;
  private suscription1: any;
  private suscription2: any;
  private suscription3: any;

  public isTemplate: boolean = (localStorage.getItem("is_template") == "false" || localStorage.getItem("is_template") == "0" ? false : true);
  public has_filter_panel: boolean = false;
  public show_filter_panel: boolean = false;
  public show_filter_panel_mobile: boolean = false;
  public componentFilter: any;
  public selectedRow: any[] = [];
  public hasFilterValue: boolean = false;
  public fieldQuicks: any[] = [];
  public selfView: any;
  public showTrackingOption: boolean = false;

  constructor(
    public debugService: DebugService,
    @Optional() private dialogRef: DynamicDialogRef,
    private viewsService: ViewsService,
    private viewsCustomService: ViewsCustomService,
    private messageService: MessageService,
    private dialogService: DialogService,
    public loginService: LoginService,
    public componentService: ComponentService,
    private entitiesService: EntitiesService,
    private usersService: UsersService,
    private sessionStorage: SessionStorageService,
    private translateService: TranslateService
  ) {
    this.selfView = this;

    //this.formGroup = this.formBuilder.group({});
    //this.formGroup.controls.registerControl('name', new AbstractControl() { Validators: ['', Validators.required]});
    this.suscription1 = this.viewsService.onEditViewChange.subscribe(
      data => {
        this.code = data;
        this.viewsService.getViewConfiguration(this.code).subscribe(
          data => {
            this.viewOrig = JSON.parse(JSON.stringify(data));
            this.selectedView = this.viewOrig.id;
            this.view = data;
            this.toogleConfigOptions();
          });
      });
    this.suscription2 = this.viewsService.onCreateViewChange.subscribe(
      filters => {
        this.newView(false, filters);
      });

    this.suscription3 = this.viewsService.onFilterChange.subscribe(data => {
      this.getIfHasFiltersValues();
    });

  }
  ngOnDestroy(): void {
    this.suscription1.unsubscribe();
    this.suscription2.unsubscribe();
    this.suscription3.unsubscribe();
  }

  ngOnInit(): void {
    //Recojo el identificador del usuario logeado:
    this.created_by = this.loginService.get("userId");
    //this.view_organizations = this.loginService.hasPermission("ORGANIZATIONS");
    this.view_meetings = this.loginService.hasPermission("MEETINGS");
    this.personalize_interface = this.loginService.hasPermission("PERSONALIZE_INTERFACE");
    this.create_reports = this.loginService.hasPermission("REPORTS_CREATE");
    this.roles_configuration = this.loginService.hasPermission("ROLES_CONFIGURATION");
    this.zonesPermission = this.loginService.hasPermission("ZONES_READ");
    this.personalized_views_read = this.loginService.hasPermission("PERSONALIZED_VIEWS_READ");
    this.personalized_views_write = this.loginService.hasPermission("PERSONALIZED_VIEWS_WRITE");
    this.views_superadmin = this.loginService.hasPermission("VIEWS_SUPERADMIN") || localStorage.getItem("isSuperAdmin") == "true";
    //this.view_attachments_block = this.loginService.hasPermission("ATTACHMENTS");


  }

  ngAfterViewInit(): void {
    this.loadView();
  }

  loadView() {
    this.viewsService.getViewConfiguration(this.code).subscribe(
      data => {
        this.viewOrig = JSON.parse(JSON.stringify(data));

        this.selectedView = this.viewOrig.id;
        this.view = data;

        if (this.dialogRef == null) {
          this.viewsService.changeView(this.code);
        } else {
          //no emitimos el changeview, no es necesario para los dialogos daba problemas con las ediciones de vistas
        }

        //cargamos entidad base si tiene
        if (this.view.configuration?.entity != null) this.loadBaseEntity();

        //si no hay layout, 1 columan
        if (this.view.configuration?.layout == null) this.view.configuration.layout = "12";

        //preparamos layout
        this.prepareLayout();

        //preparamos los filtros rápidos
        if (this.componentFilter?.filters?.allow_filter_panel) {
          this.createQuickFilters();
        }

        //si permite personalizar
        if (this.view.configuration.personalizable != false) {
          this.allowViewPersonalize = true;
        } else {
          this.allowViewPersonalize = false;
        }

        this.getIfHasFiltersValues();
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }
  getIfHasFiltersValues() {

    //Comprobamos si la vista tiene algún valor en los filtros.
    var sessionInfo = this.sessionStorage.retrieve(this.code);

    if (sessionInfo != null && sessionInfo.filters != null) {
      var aux = sessionInfo.filters.filter(m => m.value);
      let hasFilters = false;
      aux.forEach((filter) => {
        let entityField = this.entity.fields.find(m => m.id == filter.entity_field_id);
        if (this.model._excluded_fields == null || this.model._excluded_fields.indexOf(entityField.model_property) < 0) {
          hasFilters = true;
        }
      });
      this.hasFilterValue = hasFilters;
    }
  }

  loadBaseEntity() {
    //console.log("asdf this.view.configuration.entity", this.view.configuration.entity);
    this.entitiesService.getByCode(this.view.configuration.entity).subscribe({
      next: (data: any) => {
        this.entity = data;
        //console.log("asdf this.entity", this.entity);
        setTimeout(() => {
          this.onEvent.emit({ component: "view", event: "entity-loaded", view: this.view, entity: this.entity });
        }, 500);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  prepareLayout() {
    let cols = this.view?.configuration?.layout.split("-");
    let nCol = 0;
    this.layout = [];
    cols.forEach(col => {
      var components = this.getComponentsForColumn(nCol);
      this.layout.push({
        id: nCol,
        class: "p-lg-" + col,
        components: components
      });

      components.forEach((component: any) => {
        if (component.filters) {
          if (component.filters.allow_filter_panel) {
            this.has_filter_panel = true;
            this.componentFilter = component;
          }
        }
      });
      nCol++;
    });
  }

  toogleConfigOptions() {
    //TODO: Si es una vista tuya que se pueda editar, se saca las opciones
    // si no, se muestra directamente el popup de crear vista.

    if (this.personalize_interface) {
      if (this.loginService.hasPermission('VIEWS_SUPERADMIN') || this.loginService.get("isSuperAdmin")) {
        this.showConfigOptions = !this.showConfigOptions;
      } else if (this.viewOrig.id < 100000 || (this.viewOrig.user_id != null && this.viewOrig.user_id != this.loginService.get("userId"))) {
        //Es una vista de las originales, no se le permite con
        this.newView(false);
      } else {
        this.showConfigOptions = !this.showConfigOptions;
      }

    }
  }

  itemChange(item, itemComponent) {
    console.info('itemChanged', item, itemComponent);
  }

  itemResize(item, itemComponent) {
    console.info('itemResized', item, itemComponent);
  }

  drop(event, col: any) {
    //moveItemInArray(col.components, event.previousIndex, event.currentIndex);
    if (event.previousContainer === event.container) {
      moveItemInArray(col.components, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  onLayoutChange(event) {
    let totalCols = this.view.configuration.layout.split("-").length;
    //si hay componentes sin col, los mandamos a la primera
    this.view.configuration.components.forEach(component => {
      if (component.layout_col != null && component.layout_col > totalCols - 1) component.layout_col = 0;
    });
    this.prepareLayout();
  }

  getComponentsForColumn(nCol: number) {
    let components = this.view?.configuration?.components.filter(m => {
      return m.layout_col == nCol || (nCol == 0 && m.layout_col == null);
    });
    return components;
  }

  onComponentEvent(event: any) {
    if (event.event == "view-save") {
      //this.save();
    } else if (event.event == "component-left") {
      let previousIndex = event.index;
      let currentIndex = previousIndex - 1;
      if (currentIndex >= 0 && previousIndex >= 0) moveItemInArray(this.view.configuration.components, previousIndex, currentIndex);
    } else if (event.event == "component-right") {
      let previousIndex = event.index;
      let currentIndex = previousIndex + 1;
      if (currentIndex >= 0 && previousIndex >= 0) moveItemInArray(this.view.configuration.components, previousIndex, currentIndex);
    } else {
      this.onEvent.emit(event);
    }
  }

  save() {
    //pasamos componentes del layout a la configuracion
    let components: any[] = [];
    this.layout.forEach(col => {
      col.components.forEach(component => {
        component.layout_col = col.id;
        components.push(component);
      });
    });
    this.view.configuration.components = components;



    //cualqueir cambio de vista se guarda siempre en el custom
    if (this.view.id > 1000000) {
      //quitamos info de field antes de guardar
      let saveView = JSON.parse(JSON.stringify(this.view));
      saveView.configuration.components.forEach((component) => {
        component.fields?.forEach((field) => {
          delete field.entityField;
        });
      });

      this.viewsCustomService.saveView(this.view.id, saveView).subscribe(
        data => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("view.configure.save_configuration") });
          this.showConfigOptions = false;
          this.prepareLayout();
          location.reload();
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      )
    } else {
      this.viewsService.save(this.view.id, this.view).subscribe(
        data => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("view.configure.save_configuration") });
          this.showConfigOptions = false;
          this.prepareLayout();
          location.reload();
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      )
    }
  }

  cancel() {
    this.view = this.viewOrig;
    this.showConfigOptions = false;
    location.reload();
  }

  fireEvent(component: string, event: string, args: any = null) {
    var table = this.tables.filter(m => m.component.code == component);
    var card = this.cards.filter(m => m.component.code == component);
    var gallery = this.galleries.filter(m => m.component.code == component);
    var file = this.files.filter(m => m.component.code == component);
    var custom = this.customs.filter(m => m.component.code == component);

    if (card.length > 0) {
      card[0].fireEvent(component, event, args);
    } else if (gallery.length > 0) {
      gallery[0].fireEvent(component, event, args);
    } else if (table.length > 0) {
      table[0].fireEvent(component, event, args);
    } else if (file.length > 0) {
      file[0].fireEvent(component, event, args);
    } else if (custom.length > 0) {
      custom[0].fireEvent(component, event, args);
    } else {
      var form = this.forms.filter(m => m.component.code == component);
      if (form.length > 0) {
        form[0].fireEvent(component, event, args);
      }
    }

  }

  getComponent(component: string): any {

    var table = this.tables.filter(m => m.component.code == component);
    var card = this.cards.filter(m => m.component.code == component);
    var gallery = this.galleries.filter(m => m.component.code == component);
    var file = this.files.filter(m => m.component.code == component);
    var custom = this.customs.filter(m => m.component.code == component);
    var form = this.forms.filter(m => m.component.code == component);

    let ret: any = null;
    if (card.length > 0) {
      ret = card[0];
    } else if (gallery.length > 0) {
      ret = gallery[0];
    } else if (table.length > 0) {
      ret = table[0];
    } else if (file.length > 0) {
      ret = file[0];
    } else if (custom.length > 0) {
      ret = custom[0];
    } else if (form.length > 0) {
      ret = form[0];
    }
    return ret;
  }

  getComponentForm(component: string): FormGroup {
    var form = this.forms.filter(m => m.component.code == component);
    let ret: FormGroup = null;
    if (form.length > 0) ret = form[0].form;
    return ret;
  }

  addComponent() {
    const ref = this.dialogService.open(ViewComponentAddComponent, {
      data: {
        view_code: this.code
      },
      header: this.translateService.instant("view.configure.add_component"),
      width: '600px'
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        let component = data.base_component.configuration;
        component.layout_col = 0;
        component.base_component_code = data.base_component.code;
        this.layout[0].components.push(component);
      }
    })
  }

  removeComponent(col, component, i) {
    col.components.splice(i, 1);
  }

  showComponentConfigOP($event, component: any) {
    this.component = component;
    if (this.op.overlayVisible) {
      this.op.hide();
    } else {
      this.op.show($event);
    }
  }
  getEntityFromComponent() {
    return this.entity;
  }

  getEntityBaseName(plural: boolean = false, capitalize: boolean = false) {
    let title = "";
    if (this.entity?.name != null) {
      /*if (plural) {
        
        title = this.entity.name_plural;
      } else {
        title = this.entity.name;
      }*/
      title = this.componentService.getEntityName(this.entity, plural);
      title = title.toLowerCase();
      if (capitalize) title = title[0].toUpperCase() + title.substring(1);
    }
    return title;
  }
  getViewCodeName() {
    return this.view.code + " - " + this.view.name + " - " + this.view.id;
  }
  getViewIsFormEdit() {
    return this.view.code.includes("edit");
  }

  //Valida que TODOs los formularios de la vista son válidos
  getAllFormsFromViewValid() {
    var invalidsForm = this.forms.filter(m => m.form.valid == false);
    if (invalidsForm.length > 0) {
      console.log(this.forms);//pintamos los informes para poder ver cual es el invalido.
    }
    return (invalidsForm.length > 0 ? false : true);

  }

  newView(edit, filtersComponent: any = null) {
    //igual llega los filtros, si es asi hay que buscar en la vista original, y añadirselos
    if (filtersComponent != null) {
      this.viewOrig.configuration.components?.forEach(component => {
        if (filtersComponent.code == component.code) {
          component.filters = {};
          component.filters.filters = filtersComponent.filters.filters;
          component.filters.allow_filter_panel = filtersComponent.filters.allow_filter_panel;
        }
      });
    }
    const ref = this.dialogService.open(NewViewComponent, {
      data: {
        view: this.viewOrig,
        edit: edit
      },
      header: edit ? this.translateService.instant("general.filter.edit_personalize_view") : this.translateService.instant("general.filter.create_new_view"),
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadViewsDropdown();
      if (data) {
        let component = data.configuration;
        component.layout_col = 0;
        component.base_component_code = data.code;
        this.layout[0].components.push(component);
        this.loadViewsDropdown();
        location.reload();
      }
    });
  }
  editView() {
    this.showConfigOptions = !this.showConfigOptions;
    this.op.hide();
  }
  opToggleTitle(op, $event, component) {
    this.component = component;
    if (this.component.template == "form") {
      //Comprobamos si mostrar o no la opción de tracking (solo se muestra si tiene en el metadata la configuración de tracking active.)
      if (this.entity != null && this.entity.metadata?.tracking?.enabled) {
        this.showTrackingOption = true;
      } else {
        this.showTrackingOption = false;
      }
    }

    op.toggle($event);
  }
  opToggle(op, $event) {
    this.loadViewsDropdown()
    op.toggle($event);
  }
  tableColumnsAdjust() {
    if (!this.component) return;
    let component: ViewComponentTableComponent = this.getComponent(this.component.code);
    if (component) component.autoSizeCols();
  }
  delete() {
    this.viewsCustomService.delete(this.view.id).subscribe(
      data => {
        this.messageService.add({ severity: "success", detail: this.translateService.instant("general.filter.delete_view_correct") });
        location.reload();
      },
      error => {
        this.messageService.add({ severity: "error", detail: error.error.title });
      }
    );
  }
  loadViewsDropdown() {
    this.viewsService.viewsCombo({ code: this.code }).subscribe(
      data => {
        this.dropdownViews = data;
        if (this.personalized_views_write) {
          this.dropdownViews.push({
            id: -1, name: "*** " + this.translateService.instant("general.filter.create_new_view") + " ***"
          });
        }
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }

  changeCurrentView() {
    //Éste método utilizará el id de la view seleccionada (selectedView) para guardarlo en el metadata del usuario (userId).
    if (this.selectedView != -1) {
      this.usersService.changeCurrentView(this.loginService.get("userId"), this.selectedView).subscribe(
        data => {
          if (data) {
            this.viewsService.removeViewConfigurationFromCache(this.selectedView.code);
            location.reload();
          }
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    }/* else {
      this.newView(false);
    }*/
  }

  toggleFilter() {
    this.show_filter_panel = !this.show_filter_panel;
  }
  toggleFilterMobile() {
    this.show_filter_panel_mobile = !this.show_filter_panel_mobile;
  }

  exportExcel(component: any) {
    let viewComponentTable: ViewComponentTableComponent = this.getComponent(component.code);
    if (viewComponentTable == null) {
      //TODO: sacar alerta de ha ocurrido un error
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("general.no_table_founded_for_export") })
      return;
    }
    viewComponentTable.exportExcel();
  }

  isActionVisible(action: any, item: any) {
    let visible: boolean = true;
    if (action.ngIf != null) {
      visible = eval(action.ngIf);
    }
    return visible;
  }

  onActionClick(action, item, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    let auxSelectedRow = null;
    if (this.componentFilter.checked_multiple) auxSelectedRow = this.selectedRow.map(m => m[this.componentFilter.checked_multiple.value]);
    this.onEvent.emit({ event: "action", component: this.componentFilter.code, action: action, data: item, selectedRow: auxSelectedRow });
  }

  getActionLabel(action, item) {
    return this.componentService.getActionLabel(action, this.view.code);
    /*
    let label = action.label;
    //1 buscamos en el generico general.action.add
    var key = "general.action." + action.name;
    if (this.translateService.instant(key) !== key) {
      label = this.translateService.instant(key);
    }
    //2 buscamos en el especifico
    var keySpecific = "component." + this.view.code + ".action." + action.name;
    if (this.translateService.instant(keySpecific) !== keySpecific) {
      label = this.translateService.instant(keySpecific);
    }
    return label;*/
  }

  createQuickFilters() {
    //Creamos los filtros de quick
    this.componentFilter?.filters?.quick_filter_fields?.forEach(item => {
      let field = this.entity?.fields.find(m => m.id == item);
      this.fieldQuicks.push(field);

      //TODOOOOOO:
      //Buscamos si esta la propiedad en la sesion para ponérselo al modelo (PORQUÉ NO LLEGA EL MODELO???)
      /*var sessionInfo = this.sessionStorage.retrieve(this.code);
      if (sessionInfo != null && sessionInfo.filters != null) {
        var aux = sessionInfo.filters.filter(m => m.value);
        aux.forEach((filter:any) => {
          if (filter.entity_field_id + "" == field.id + "") {
            this.model[field.model_property] = filter.value;
          }
        });
      }*/
    });
  }

  clearfilter(quick_model_property: any) {
    this.model[quick_model_property] = "";

    this.getIfHasFiltersValues();
    //lanzamos un reload de la tabla
    let eventPayload: any = { event: "action", valid: true, component: this.componentFilter.code, action: { name: "search" }, data: this.model };
    this.onEvent.emit(eventPayload);
  }

  reloadData(quick_model_property) {
    this.model["_op_" + quick_model_property] = "like";
    this.getIfHasFiltersValues();
    //lanzamos un reload de la tabla
    let eventPayload: any = { event: "action", valid: true, component: this.componentFilter.code, action: { name: "search" }, data: this.model };
    this.onEvent.emit(eventPayload);
  }

}
